<template>
  <el-row>
    <el-col :lg="{span: 10, offset: 7}">
      <el-card  class="overflow-visible" v-loading="isLoading">
        <template #header>
          <div>
            <h2 class="heading page-heading">
              {{ $t('page_post_update.title') }}
            </h2>
            <CurrentUnitInfo />
          </div>
        </template>
        <template #default>
          <el-form
              v-model="form"
              :rules="formRules"
              status-icon
              :model="form"
              ref="form"
          >
            <el-row>
              <el-col :span="24">
                <el-form-item :label="$t('page_post_update.fields.title')" prop="title">
                  <el-input v-model="form.title" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('page_post_update.fields.photo')" prop="photo">
                  <v-file-input v-model="form.photo" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item :label="$t('page_post_update.fields.description')" prop="description">
                  <el-input :maxLength="200" v-model="form.description" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item prop="content">
                  <label class="el-form-item__label el-form-item__label--float-none">
                    {{ $t('page_post_update.fields.content') }}
                  </label>
                  <v-text-editor @input="validateEditor" v-model="form.content" />
                </el-form-item>
              </el-col>
              <el-col class="card-actions" :span="24">
                <el-button type="primary" @click="handleSubmit">
                  {{ $t('page_post_update.btn_submit') }}
                </el-button>
                <el-button @click="goPostPage">
                  {{ $t('page_post_update.btn_cancel') }}
                </el-button>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import {POST_VIEW_ROUTE_NAME} from '../../../shared/constants/app-routes-names'

import VTextEditor from '../../../components/ui/VTextEditor'
import VFileInput from '../../../components/ui/v-file-input/VFileInput'

import {fetchPostByID, updatePost} from '../shared/services/post-service'
import CurrentUnitInfo from '../../units/components/current-unit-info/CurrentUnitInfo'

export default {
  name: 'PostEdit',
  components: {
    CurrentUnitInfo,
    VTextEditor,
    VFileInput
  },
  data() {
    return {
      isLoading: false,
      form: {
        title: '',
        photo: '',
        description: '',
        content: '',
      },
      formRules: {
        title: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
        description: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
        content: [
          {required: true, message: this.$t('validators.field_required'),}
        ],
      }
    }
  },
  created() {
    this.fetchPost()
  },
  methods: {
    fetchPost() {
      const { postID } = this.$route.params
      this.isLoading = true
      fetchPostByID(postID)
          .then((res) => {
            this.form = {...res}
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        const {postID} = this.$route.params
        this.isLoading = true
        updatePost(this.form, postID)
            .then(() => {
              this.goPostPage()
              this.$message({
                message: `${this.$t('page_post_update.messages.update_success')}`,
                type: 'success'
              })
            })
            .catch(() => {
              this.$message({
                message: `${this.$t('page_post_update.messages.update_error')}`,
                type: 'error'
              })
            })
            .finally(() => {
              this.isLoading = false
            })
      })
    },
    validateEditor() {
      this.$refs.form.validateField('content')
    },
    goPostPage() {
      const {unitID, postID} = this.$route.params
      this.$router.push({
        name: POST_VIEW_ROUTE_NAME,
        params: {unitID, postID}
      })
    }
  }
}
</script>

<style scoped>

</style>
