<template>
    <div @click="triggerFileInput">
      <el-form
          :rules="formRules"
          status-icon
          :model="form"
      >
        <el-form-item prop="internalValue">
          <el-input
              readonly
              :placeholder="placeholder"
              :value="inputValue"
          >
            <template #prefix>
              <v-icon name="attach_file"/>
            </template>
          </el-input>
          <input
              ref="fileInput"
              hidden
              :multiple="multiple"
              type="file"
              :accept="accept"
              @change="handleChange"
          />
        </el-form-item>
      </el-form>
    </div>
</template>

<script>
import VIcon from '../v-icon/VIcon'
export default {
  name: 'VFileInput',
  components: {VIcon},
  props: {
    value: {
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    multiple: {
      type: Boolean,
      default: false
    },
    acceptableFormats: {
      type: Array,
      default: () => []
    },
    maxSize: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      form: {
        internalValue: []
      },
      formRules: {
        internalValue: [
          {
            trigger: 'change',
            validator: this.validateSize
          }
        ]
      }
    }
  },
  computed: {
    inputValue() {
      let result

      if (this.value?.length > 1) {
        result = `${this.value.length} ${this.$t('components.file_input.files')}`
        return result
      }

      if (this.multiple && this.value?.length === 1) {
        result = this.value[0].name
        return result
      }

      return this.value?.name
    },
    accept() {
      return this.acceptableFormats.join(',')
    },
    totalFileSize() {
      if (this.form.internalValue.length > 0) {
        return this.form.internalValue
            .map(({size}) => size)
            .reduce((prev, current) => prev + current)
      }
      return 0
    },
    maxSizeInBytes() {
      return this.maxSize * 1024 * 1024
    }
  },
  methods: {
    validateSize(_, __, callback) {
      if (this.totalFileSize > this.maxSizeInBytes) {
        callback(this.$t('validators.max_file_size_exceed', { maxSize: this.maxSize }))
      }
    },
    handleChange(e) {
      const { files } = e.target
      this.form.internalValue = Array.from(files)
      const result = this.multiple ? files : files[0]
      this.$emit('input', result)
    },
    triggerFileInput() {
      this.$refs.fileInput.click()
    }
  }
}
</script>

<style scoped>

</style>
