import {api} from '../../../../shared/services/backend-api'

const createNewPost = async (post, unitID) => {
  
  const payload = new FormData()
  Object.entries(post)
    .forEach(([key, val]) => {
      payload.append(key, val)
    })
  
  const { data } = await api.post(`units/${unitID}/post`, payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
  return { postID: data }
}

const updatePost = (post, postID) => {
  const payload = new FormData()
  Object.entries(post)
    .forEach(([key, val]) => {
      payload.append(key, val)
    })
  return api.post(`posts/${postID}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

const removePost = (postID) => {
  return api.delete(`posts/${postID}`)
}

const publishPost = (postID) => {
  return api.put(`posts/${postID}/publish`)
}

const unpublishPost = (postID) => {
  return api.put(`posts/${postID}/unpublish`)
}

const fetchPostByID = async (postID) => {
  const { data } = await api.get(`posts/${postID}`)
  const { title, content, description } = data
  
  return {
    title,
    content,
    description
  }
}

export {
  createNewPost,
  updatePost,
  fetchPostByID,
  removePost,
  publishPost,
  unpublishPost
}
